import moment from "moment";
import React, { useRef, useEffect, useCallback } from "react";
import { useProductFruitsApi } from "react-product-fruits";

import ProductFruitsContext from "../../../providers/ProductFruitsProvider";
import useUserData from "../../../hooks/useUserData";
import ProductFruits from "../../../productFruits";
import { SURVEYS } from "../../../utils/constants";
import updateUserSurveysMutation from "../../../mutations/updateUserSurveysMutation";
import { useMutation } from "react-apollo";
import { showError } from "./Notify";
import { waitFor } from "../../../utils/SharedUtils";

const ProductFruitsProvider = ({ children }) => {
  const apiRef = useRef(null);
  const { token, pf } = useUserData();
  const temporalSurveyRef = useRef(null);
  const checkSurveyFunctionRef = useRef(() => {});

  const [updateSurvey] = useMutation(updateUserSurveysMutation, {
    refetchQueries: ["UserData"],
    variables: {
      token
    }
  });

  useProductFruitsApi(api => {
    apiRef.current = api;

    const disposeFn = api.surveys.listen("survey-closed", e => {
      if (e.reason === "close_button") {
        return;
      }

      temporalSurveyRef.current = {
        ...(temporalSurveyRef.current || {}),
        [e.surveyId]: new Date()
      };

      updateSurvey({
        variables: {
          surveyId: e.surveyId
        }
      }).catch(err => showError(err.message));
    });

    return () => {
      disposeFn();
    };
  }, []);

  checkSurveyFunctionRef.current = useCallback(
    surveyId => {
      try {
        const userSurveys = Object.assign(
          {},
          pf?.surveys || {},
          temporalSurveyRef.current || {}
        );

        // 1: if the survey is already seen
        if (userSurveys[surveyId]) {
          return false;
        }
        const oneDayAgo = moment().subtract(1, "day");

        // 2: if last seen survey was more than 3 days
        const cantAppear = Object.values(userSurveys).some(date =>
          moment(date).isAfter(oneDayAgo)
        );
        if (cantAppear) {
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    },
    [pf]
  );

  const startSurvey = useCallback(type => {
    const surveyId = SURVEYS[type];
    const checkSurvey = checkSurveyFunctionRef.current || (() => {});
    const check = checkSurvey(surveyId);

    if (!check) {
      return;
    }

    apiRef.current.surveys.startSurvey(surveyId);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      await waitFor(checkIfModalClose, 1000);

      startSurvey("GENERAL");
    }, 30000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <ProductFruitsContext.Provider
      value={{
        startSurvey
      }}
    >
      {children}
      <ProductFruits />
    </ProductFruitsContext.Provider>
  );
};

export default ProductFruitsProvider;

const checkIfModalClose = () => {
  const dialogModal = document.querySelector(".MuiDialog-root");
  return !dialogModal;
};
