import { UAParser } from "ua-parser-js";

import useCompany from "./useCompany";
import useUserData from "./useUserData";
import { TrackEvent as OriginalTrackEvent } from "../modules/segment";
import { isDemoEnviroment } from "../config/config";
import { loadStateKey } from "../actions/DataPersistence";

const useEventTracking = () => {
  const user = useUserData();
  const company = useCompany();
  const accountId = company?.selectedCompany;
  const email = user?.email;
  const companyName = company?.name;
  const userRoles = company?.companyRoles;
  const isDemo = isDemoEnviroment();
  const stateUser = loadStateKey("s24-user", "user");
  const impersonating = stateUser?.impersonating;

  const getDeviceType = async () => {
    const parser = new UAParser();
    const device = await parser
      .getDevice()
      .withFeatureCheck()
      .withClientHints();

    return device.type || "desktop";
  };

  const getScreenType = () => {
    if (typeof window === "undefined" || window.innerWidth === undefined) {
      throw new Error("Can't get screen type as window or width is undefined");
    }
    const width = window.innerWidth;

    if (width < 768) {
      return "mobile";
    }

    if (width < 1280) {
      return "tablet";
    }

    return "desktop";
  };

  const TrackEvent = async (eventName, eventProperties = {}) => {
    if (impersonating) {
      return;
    }

    const size = getScreenType();
    const device = await getDeviceType();

    OriginalTrackEvent(eventName, {
      ...eventProperties,
      size,
      device,
      accountId,
      email,
      companyName,
      userRoles,
      ...(isDemo ? { isDemo: true } : {})
    });
  };

  return {
    TrackEvent
  };
};

export default useEventTracking;
