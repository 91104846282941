import { useState, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "react-apollo";

import useEventTracking from "./useEventTracking";
import { showError, showSuccess } from "../components/core/shared/Notify";
import GetPickupsData from "../queries/GetPickupsData";
import {
  ConfirmPickup as ConfirmPickupMutation,
  ReschedulePickup as ReschedulePickupMutation
} from "../mutations/pickupsMutations";
import UserContext from "../providers/UserProvider";
import GetSinglePickupDataById from "../queries/GetSinglePickupDataById";
import { useValidation } from "./useValidation";
import useStandardLocalization from "./useStandardLocalization";
import { useProductFruitsSurvey } from "./useProductFruitsSurvey";
export const usePickupForm = ({
  pickupId,
  onClose,
  pickupStatusCondition,
  item,
  setDisabledButton = () => {}
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const background = useLocation();
  const { TrackEvent } = useEventTracking();
  const { user, selectedCompany, userData } = useContext(UserContext);
  const { startSurvey } = useProductFruitsSurvey();
  // date options
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { errors, setErrors, checkValidation } = useValidation();
  const { parseDate } = useStandardLocalization();

  // form details
  const [form, setForm] = useState(() => ({
    quantity: "",
    packaging: "",
    comments: ""
  }));

  const [confirmPickup] = useMutation(ConfirmPickupMutation, {
    refetchQueries: () => [
      {
        query: GetPickupsData,
        variables: {
          token: user?.token,
          partnerId: selectedCompany
        }
      }
    ]
  });

  // pick up query
  const [
    SinglePickupDataById,
    { loading: loading_single_pickup, error: pickupQueryError }
  ] = useLazyQuery(GetSinglePickupDataById, {
    fetchPolicy: "cache-and-network"
  });

  // use to refetch query after updates when needed
  const onRefetchPickupQuery = () => {
    // update item to show new uploads
    SinglePickupDataById({
      variables: {
        token: user?.token,
        pickupId,
        partnerId: selectedCompany
      }
    });
  };

  const [reschedulePickup, { loading }] = useMutation(
    ReschedulePickupMutation,
    {
      refetchQueries: () => [
        {
          query: GetPickupsData,
          variables: { token: user?.token, partnerId: selectedCompany }
        }
      ]
    }
  );

  const pickUpDateFormatted = parseDate(item.pickupOrderDate);

  // decide which modal to show - reschedule or confirm
  const request = useMemo(() => {
    if (parseInt(pickupStatusCondition) === 1) {
      return "confirm";
    }

    return "reschedule";
  }, [pickupStatusCondition]);

  // update initial form values
  useEffect(() => {
    setForm({
      quantity: item.quantity,
      packaging: item.packaging || "",
      comments: ""
    });
  }, [item.quantity, item.packaging, item.comments]);

  const onSubmit = () => {
    const quantity = parseFloat(form.quantity);
    const { RawUserData } = userData;

    if (request === "confirm") {
      setDisabledButton(true);
      confirmPickup({
        variables: {
          token: user?.token,
          pickupId: item._id,
          quantity,
          additionalComments: form.comments
        }
      })
        .then(() => {
          TrackEvent("Pick up confirmed", {
            quantity,
            isQuantityChanged: quantity !== item.quantity / 1000,
            orderId: item.transactionId,
            userName: RawUserData.firstName + " " + RawUserData.lastName,
            pickupDate: pickUpDateFormatted,
            isApproximated: false
          });
          history.replace(background.pathname, null);
          setDisabledButton(false);
          showSuccess(
            t("FeedbackNotification_PickupRequestConfirmed", {
              date: pickUpDateFormatted
            })
          );
          // close modal after successfully done
          onClose();
        })
        .catch(err => showError(err.message));
    }

    if (request === "reschedule") {
      const hasErrors = checkValidation({ date, quantity });
      if (hasErrors) {
        return;
      }

      if (date === "dateRange" && (!startDate || !endDate)) {
        setErrors(prev => ({
          ...prev,
          date: { error: true }
        }));
        return;
      }

      if (date === "date" && !startDate) {
        setErrors(prev => ({
          ...prev,
          date: { error: true }
        }));
        return;
      }

      let pickUpDate;

      if (
        (date === "date" || date === "immediately") &&
        startDate &&
        endDate &&
        startDate === endDate
      ) {
        pickUpDate = startDate;
      }

      let collectionType =
        date === "date" || date === "immediately"
          ? "date"
          : date === "dateRange"
            ? "dateRange"
            : date === "2weeks"
              ? "within2Weeks"
              : null;

      const dateRange = `${startDate} - ${endDate}`;

      setDisabledButton(true);

      reschedulePickup({
        variables: {
          token: user?.token,
          pickupId: item._id,
          quantity,
          collectionType: collectionType,
          date: pickUpDate,
          dateRange: collectionType === "dateRange" ? dateRange : null,
          additionalComments: form.comments
        }
      })
        .then(() => {
          TrackEvent("Reschedule pickup completed", {
            clickSource: "Button",
            quantity,
            isQuantityChanged: quantity !== item.quantity / 1000,
            orderId: item._id,
            oldDate: pickUpDateFormatted,
            newDate: pickUpDate || startDate,
            userName: RawUserData.firstName + " " + RawUserData.lastName,
            isApproximated: date !== "date"
          });
          setDisabledButton(false);
          history.push("/pickups", {
            background: {
              pathname: location.pathname,
              search: location.search,
              hash: location.hash
            },
            pickupId: item.transactionId
          });
          showSuccess(
            t("FeedbackNotification_PickupRescheduleSent", {
              transaction_id: item.transactionId
            })
          );
          startSurvey("PICKUP_REQUEST");
          // close modal after successfully done
          onClose();
        })
        .catch(err => showError(err.message));
    }
  };

  const onChange = e => {
    e.persist();
    setForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return {
    background,
    user,
    selectedCompany,
    form,
    date,
    startDate,
    endDate,
    pickUpDateFormatted,
    loading_single_pickup,
    pickupQueryError,
    request,
    errors,
    setErrors,
    setDate,
    setStartDate,
    setEndDate,
    onChange,
    onSubmit,
    onRefetchPickupQuery
  };
};
