import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { useRedirection } from "../../hooks/useRedirection";
import CustomDropzoneComponent from "../core/shared/uploadDialog/components/Dropzone/index";

import {
  Typography,
  Grid,
  makeStyles,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  InputAdornment,
  Hidden,
  FormHelperText
} from "@material-ui/core";
import { Add, CalendarTodaySharp, Close, Delete } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";
import moment from "moment";

import {
  FLAG_AS_SALE_REQUESTED,
  GET_FULL_PRODUCT_DATA
} from "../../queries/Products";
import GetAllStorageTypes from "../../queries/GetAllStorageTypes";

import Loader from "../core/shared/Loader";
import { showError, showSuccess, showInfo } from "../core/shared/Notify";
import frequencyRates from "../../utils/collections/frequencyRates";
import useEventTracking from "../../hooks/useEventTracking";
import GetSingleProductByIdForRequest from "../../queries/GetSingleProductByIdForRequest";
import { useValidation } from "../../hooks/useValidation";
import { SelectMenuProps } from "../core/shared/constants";
import { ALLOWED_DEMO_FREQUENCY_TYPES } from "../../utils/constants";
import { isDemoEnviroment as _isDemoEnviroment } from "../../config/config";
import { getProductImageUrl } from "./card/ProductUtils";
import { useProductFruitsSurvey } from "../../hooks/useProductFruitsSurvey";
const SaleRequest = props => {
  const { user, selectedCompany, params_id } = props;
  const { startSurvey } = useProductFruitsSurvey();
  const notificationAndRedirection = useRedirection();
  const { TrackEvent } = useEventTracking();

  const saleRequestRedirection = () => {
    notificationAndRedirection({
      url: "/products",
      state: { refetch: true }
    });
    startSurvey("SALE_REQUEST");
  };
  const id = parseInt(params_id);

  const productQueryVariables = {
    token: user?.token,
    productId: id,
    partnerId: selectedCompany === null ? "all" : selectedCompany
  };

  const {
    loading: loading_single_product,
    data: productQueryData,
    error: productQueryError
  } = useQuery(GetSingleProductByIdForRequest, {
    variables: productQueryVariables,
    fetchPolicy: "cache-and-network"
  });

  const [packaging, setPackaging] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [comments, setComments] = useState(null);
  const [fileList, setFile] = useState([]);
  const [requested, setRequested] = useState(false);

  const useStyles = makeStyles(theme => ({
    title: {
      textTransform: "capitalize",
      letterSpacing: 0.25
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.6)",
      margin: 0,
      paddingTop: 8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: 0.4
      }
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 492,
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 1000,
        background: "#FFFFFF",
        padding: "0 16px 30px"
      }
    },
    headerContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        gap: 16,
        paddingTop: 16
      }
    },
    cardContainer: {
      display: "flex",
      gap: 16,
      padding: 20,
      background: "#F9F9F9",
      border: "1px solid #E0E0E0",
      borderRadius: 4,
      marginBottom: 4,
      marginBottom: 20,

      "& h6": {
        padding: "8px 0 4px",
        letterSpacing: 0.25
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 8
      }
    },
    imageWrapper: {
      display: "flex"
    },
    imageContainer: {
      minWidth: 100,
      width: 100,
      height: 132,
      "&:hover": {
        cursor: "pointer"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 100,
        "& > img": {
          objectFit: "cover"
        }
      }
    },
    image: {
      width: "100%",
      height: "100%"
    },
    inputsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 20,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap"
      },
      "& .MuiFormControl-root": {
        [theme.breakpoints.down("sm")]: {
          width: "100%"
        }
      }
    },
    textInput: {
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9E9E9E"
      },
      "& label": {
        color: "rgba(0, 0, 0, 0.6) !important"
      }
    },
    dropdownInput: {
      width: "100%",
      maxWidth: 236,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9E9E9E"
      }
    },
    datePicker: {
      width: "100%",
      height: 40,
      border: "1px solid #9E9E9E",
      paddingRight: "15px",
      cursor: "pointer",
      "& > input": {
        padding: "10px 0 10px 16px",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: 0.15,
        color: pickupDate ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)"
      }
    },
    datePickerWrapper: {
      "& .MuiFormControl-root": {
        width: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    dropzone: {
      background: "rgba(21, 17, 36, 0.05)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: 8,
      marginTop: 4,
      width: "100%",
      minHeight: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 4,
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: 20
    },
    textField: {
      height: "104px",
      alignItems: "baseline",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16,
      [theme.breakpoints.down("sm")]: {
        marginBottom: -14
      }
    },
    deleteIcon: {
      position: "absolute",
      top: "-14px",
      right: 0,
      cursor: "pointer"
    },
    loaderContainer: {
      width: "100%",
      height: 600,
      [theme.breakpoints.down("sm")]: {
        height: 300
      }
    },
    rowContainer: {
      display: "flex",
      gap: 16
    },
    previewsContainer: {
      height: 100,
      width: 100,
      position: "relative",
      marginRight: 12,
      marginTop: 20,
      "& > img": {
        objectFit: "cover"
      }
    },
    formContainer: {
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
        gap: 20
      }
    }
  }));

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [saleRequestMutation, { loading }] = useMutation(
    FLAG_AS_SALE_REQUESTED,
    {
      variables: {
        partnerId: selectedCompany
      },
      refetchQueries: () => [
        {
          query: GET_FULL_PRODUCT_DATA,
          variables: {
            token: user.token,
            companyId: selectedCompany
          }
        }
      ]
    }
  );

  const { loading: packagingLoading, data } = useQuery(GetAllStorageTypes, {
    variables: { token: user.token },
    fetchPolicy: "cache-and-network"
  });

  const { errors, checkValidation } = useValidation({
    quantity: quantity => {
      quantity = parseFloat(quantity);

      if (isNaN(quantity))
        return {
          error: true,
          errorText: t("pickup_request_quantity_error")
        };

      if (quantity <= 0) {
        return {
          error: true,
          errorText: t("Error_ValueTooLow")
        };
      }

      if (!quantity) {
        return {
          error: true
        };
      }

      return null;
    }
  });

  if (loading || packagingLoading || loading_single_product)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );

  const item = productQueryData?.SingleProductByIdForRequest;

  if (productQueryError) {
    const errorMessage = productQueryError.graphQLErrors[0].message.substring(
      1,
      productQueryError.graphQLErrors[0].message.length - 1
    );

    if (errorMessage === "Already_contracted_product") {
      showInfo(t(errorMessage));
      return <Redirect to={"/products"} />;
    }
    if (errorMessage === "Product_not_found") {
      showError(t(errorMessage));
      return <Redirect to={"/products"} />;
    }
  }
  if (!(id && item && item.productId)) return <Redirect to="/products" />;

  if (item.quantity && !quantity) setQuantity(item.quantity);

  const saleRequest = () => {
    setRequested(true);

    const errors = checkValidation({ packaging, quantity, frequency });
    if (errors) return;

    const validQuantity =
      quantity && quantity.includes(",") && !quantity.includes(".")
        ? quantity.replace(/,/g, ".")
        : quantity;

    saleRequestMutation({
      variables: {
        token: user.token,
        productId: item.productId,
        packaging,
        quantity: validQuantity,
        frequency,
        pickupDate,
        aditionalComments: comments,
        file: fileList && fileList.length > 0 ? fileList : []
      }
    })
      .then(response => {
        const newLeadId = response.data.saleRequested.leadId;

        showSuccess(
          t("Notification sale request", {
            material_alias: item.aliasForLanguage || item.alias
          })
        );
        TrackEvent("Sale request completed", {
          conditionId: item.conditionId,
          quantity,
          packaging,
          frequency,
          pickupDate,
          comments,
          leadId: newLeadId
        });
        saleRequestRedirection();
      })
      .catch(err => showError(t(err.message)));
  };

  const calendarLocale =
    i18n.language === "de"
      ? deLocale
      : i18n.language === "es"
        ? esLocale
        : enLocale;

  const isDemoEnviroment = _isDemoEnviroment();

  const filteredFrequencyRates = frequencyRates.filter(
    rate => !isDemoEnviroment || ALLOWED_DEMO_FREQUENCY_TYPES.includes(rate)
  );

  return (
    <div className={classes.contentContainer}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={() => saleRequestRedirection()} size="small" />
        </Hidden>
        <Typography variant="h6" className={classes.title}>
          {t("Sale Request")}
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.cardContainer}>
          <div className={classes.imageContainer}>
            <Hidden mdUp>
              <img
                src={getProductImageUrl(item, 450, 100)}
                alt={item.image.productName}
                className={classes.image}
              />
            </Hidden>
            <Hidden smDown>
              <img
                src={getProductImageUrl(item, 100, 132)}
                alt={item.image.productName}
                className={classes.image}
              />
            </Hidden>
          </div>

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                {item.aliasForLanguage || item.alias}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.rowContainer}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Price")}
                </Typography>
                {
                  /* At this point we don't have a price yet*/
                  "-"
                }
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Price Validity Date")}
                </Typography>
                {(item.priceOfferValidity &&
                  moment(item.priceOfferValidity).format("DD.MM.YY")) ||
                  "-"}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.rowContainer}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Incoterms")}
                </Typography>
                {item.incoterms || "-"}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Payment Terms")}
                </Typography>
                {item.paymentTerms || "-"}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid container className={classes.inputsContainer}>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.textInput}
              id="outlined-textarea"
              label={t("Quantity") + " (mt)"}
              value={quantity}
              variant="outlined"
              onChange={event => {
                requested &&
                  checkValidation({
                    packaging,
                    quantity: event.target.value,
                    frequency
                  });
                setQuantity(event.target.value);
              }}
              error={errors?.quantity}
              helperText={
                errors?.quantity &&
                (errors?.quantity.errorText || t("Required"))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.dropdownInput}>
              <InputLabel id="packaging-dropdown">{t("Packaging")}</InputLabel>
              <Select
                labelId="packaging-dropdown"
                id="packaging-select"
                label={t("Packaging")}
                value={packaging}
                onChange={event => {
                  requested &&
                    checkValidation({
                      packaging: event.target.value,
                      quantity,
                      frequency
                    });
                  setPackaging(event.target.value);
                }}
                error={errors?.packaging}
                MenuProps={SelectMenuProps}
              >
                {data?.allStorageTypes.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.packaging && (
                <FormHelperText error>{t("Required")}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.inputsContainer}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.dropdownInput}>
              <InputLabel id="frequency-dropdown">{t("Frequency")}</InputLabel>
              <Select
                labelId="frequency-dropdown"
                id="frequency-select"
                label={t("Frequency")}
                value={frequency}
                onChange={event => {
                  requested &&
                    checkValidation({
                      packaging,
                      quantity,
                      frequency: event.target.value
                    });
                  setFrequency(event.target.value);
                }}
                error={errors?.frequency}
                MenuProps={SelectMenuProps}
              >
                {filteredFrequencyRates.map(item => (
                  <MenuItem key={item} value={item}>
                    {t(item)}
                  </MenuItem>
                ))}
              </Select>
              {errors?.frequency && (
                <FormHelperText error>{t("Required")}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerWrapper}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={calendarLocale}
            >
              <DatePicker
                name="date"
                value={pickupDate}
                onChange={date => setPickupDate(date)}
                label={
                  pickupDate
                    ? t("Pickup Date")
                    : t("Products_PickupDateOptional")
                }
                inputVariant="outlined"
                format="dd.MM.y"
                disablePast
                shouldDisableDate={shouldDisableDate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp color="action" />
                    </InputAdornment>
                  )
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <div>
          {" "}
          <CustomDropzoneComponent
            className={classes.dropzone}
            accept="image/jpeg,image/png"
            onDrop={newFile => {
              TrackEvent("Photo added to sale request");
              setFile([...fileList, ...newFile]);
            }}
            disableClick={false}
            dragText={t("DropFilesHere")}
            dragSubText={" "}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Add /> {t("Add photo")}
            </div>
          </CustomDropzoneComponent>
          <div className={classes.imageWrapper}>
            {fileList.map((image, index) => (
              <div className={classes.previewsContainer}>
                <Delete
                  className={classes.deleteIcon}
                  onClick={() => {
                    setFile(fileList.filter(item => item !== fileList[index]));
                  }}
                />
                <img
                  src={image.preview}
                  width="100%"
                  height="100%"
                  // Revoke data uri after image is loaded
                />
              </div>
            ))}
          </div>
        </div>
        <TextField
          multiline
          maxRows={4}
          variant="outlined"
          label={t("Additional Comments")}
          InputProps={{ className: classes.textField }}
          fullWidth
          value={comments}
          onChange={event => setComments(event.target.value)}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={() => {
            TrackEvent("Sale request canceled", {
              conditionId: item.conditionId,
              quantity,
              packaging,
              frequency,
              pickupDate,
              comments
            });
            saleRequestRedirection();
          }}
          color="primary"
          variant="outlined"
        >
          {t("Cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={saleRequest}>
          {t("Request")}
        </Button>
      </div>
    </div>
  );
};

export default SaleRequest;

export const isWeekend = date => date.getDay() === 0 || date.getDay() === 6;

export const shouldDisableDate = date => {
  const now = new Date();

  // If current time is 12:00 PM or later, consider "today" as day 1 of the next 4 days.
  const startDay = now.getHours() >= 12 ? 0 : 1;

  let disabledDaysCount = 0;
  let i = startDay;

  while (disabledDaysCount < 4) {
    const tempDate = new Date(now);
    tempDate.setDate(tempDate.getDate() + i);

    if (!isWeekend(tempDate)) {
      // If it's not a weekend, increase the count of disabled days
      disabledDaysCount++;
    }

    if (
      date.getDate() === tempDate.getDate() &&
      date.getMonth() === tempDate.getMonth() &&
      date.getFullYear() === tempDate.getFullYear()
    ) {
      return true; // Disable this date
    }

    i++;
  }

  // Use the isWeekend function to check if the date is on a weekend
  return isWeekend(date);
};
