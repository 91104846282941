import React, { useState, useContext } from "react";
import {
  getProductImageUrl,
  getQuantityString,
  getTitle,
  renderPriceValue
} from "./ProductUtils";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { useLazyQuery } from "react-apollo";
import PickUpStatus from "./PickUpStatus";
import AddressLabel from "../../general/label/address";
import ModalExpandedCard from "./ModalExpandedCard";
import DropdownMenu from "./DropdownMenu";
import NextPickUpContent from "./NextPickUpContent";
import moment from "moment";
import { useRequestPickupHandler } from "./RequestPickUpInfoHandler";
import useEventTracking from "../../../hooks/useEventTracking";
import ExistingPickUpModal from "../../pickups/PickupsCard/Modals/ExistingPickUpModal";
import GetRequestPickUpInfo from "../../../queries/GetRequestPickUpInfo";
import UserContext from "../../../providers/UserProvider";
import { renderPickUpStatus, pickUpStatusText } from "./ProductUtils";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import { Edit } from "@material-ui/icons";

const MobileProductCard = props => {
  const {
    item,
    changeImageAction,
    saleRequestInitializedEvent = () => {}
  } = props;
  const { user, selectedCompany } = useContext(UserContext);
  const [isExpanded, setExpanded] = useState(false);
  const [hasExistingPickups, setHasExistingPickups] = useState(false);
  const useStyles = makeStyles(theme => ({
    productContainer: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      padding: 20,
      background: "#F9F9F9",
      border: "1px solid #E0E0E0",
      borderRadius: 4
    },
    contractedBar: {
      padding: "1px 4px",
      position: "absolute",
      transform: "translate(-20px, 13px)",
      background: "#52F597",
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: 1.5,
      lineHeight: "16px",
      zIndex: 100
    },
    imageContainer: {
      width: "100%",
      height: 100,
      display: "flex",
      position: "relative",
      "& > img": {
        objectFit: "cover"
      }
    },
    image: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 500
      }
    },
    pickUpStatusContainer: {
      marginBottom: "4px",
      display: "flex",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      alignItems: "center",
      letterSpacing: "0.4px",
      color: "rgba(0, 0, 0, 0.38)",
      marginRight: "8px"
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "16px 0 12px",
      alignItems: "center",
      "& h6": {
        letterSpacing: 0.25
      }
    },
    statusContainer: {
      display: "flex",
      paddingBottom: 4
    },
    saleStatusContainer: {
      display: "flex",
      alignItems: "center",
      gap: 8,
      padding: "0 4px 4px",
      color: "rgba(0, 0, 0, 0.38)",
      "& > svg": {
        fontSize: 12
      },
      "& > p": {
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: 0.4
      }
    },
    active: {
      color: "rgba(0, 0, 0, 0.6)",
      "& > svg": {
        color: "#52F597"
      },
      "& > p": {
        cursor: "pointer"
      }
    },
    labelContainer: {
      display: "flex",
      width: "fit-content",
      marginBottom: "11px"
    },
    expired: {
      color: "rgba(0, 0, 0, 0.6)",
      "& > svg": {
        color: "#E11900"
      },
      "& > p": {
        cursor: "pointer"
      }
    },
    descriptionContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 16
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.6)",
      margin: 0,
      paddingTop: 8,
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "16px"
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16,
      marginTop: 20
    },
    greyStatus: {
      color: "rgba(0, 0, 0, 0.38)",
      "& > svg": {
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: "12px",
        marginRight: "8px"
      }
    },
    greenStatus: {
      color: "rgba(0, 0, 0, 0.6)",
      "& > svg": {
        color: "#4ef191",
        fontSize: "12px",
        marginRight: "8px"
      }
    },
    editImagePlaceholder: {
      width: "100%",
      height: 100,
      objectFit: "cover",
      position: "absolute",
      cursor: "pointer",
      "&:not(:hover) > div": {
        position: "absolute",
        bottom: "4px",
        right: "4px",
        display: "flex",
        padding: 4,
        color: "rgba(255, 255, 255, 0.75)",
        backgroundColor: "transparent",
        cursor: "pointer"
      },
      "&:hover > div": {
        position: "absolute",
        bottom: "4px",
        right: "4px",
        display: "flex",
        padding: 4,
        color: "rgb(82, 245, 151)",
        backgroundColor: "rgb(21, 17, 36)",
        cursor: "pointer",
        zIndex: 100
      }
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { TrackEvent } = useEventTracking();

  const { handleOnCompleted, handleOnError } = useRequestPickupHandler(
    item,
    history,
    location,
    setHasExistingPickups
  );

  const [GetPickUpInfo, { loading, data, error }] = useLazyQuery(
    GetRequestPickUpInfo,
    {
      fetchPolicy: "network-only",
      onCompleted: handleOnCompleted,
      onError: handleOnError
    }
  );

  const onEventRequest = source => {
    if (item.statusId === 1) {
      TrackEvent("Pick up requested", {
        source: source,
        productId: item.productId,
        quantity: item.quantity,
        conditionsId: item.conditionId
      });
    } else if (item.statusId !== 3) {
      saleRequestInitializedEvent(source, item);
    }

    GetPickUpInfo({
      variables: {
        token: user?.token,
        conditionId: item.conditionId,
        partnerId: selectedCompany
      }
    });
  };

  const pickUpStatus = pickUpStatusText(t)[item.statusId] || "";
  const showPickupRequest = item.showPickupRequest;
  const { parseDate, parseCurrency } = useStandardLocalization();
  return (
    <>
      <ModalExpandedCard
        item={item}
        closeModal={() => setExpanded(false)}
        isOpen={isExpanded}
        onEventRequest={onEventRequest}
      />
      <div className={classes.productContainer}>
        {item.statusId === 1 && (
          <div className={classes.contractedBar}>{t("CONTRACTED")}</div>
        )}
        <div className={classes.imageContainer}>
          <img
            src={getProductImageUrl(item, 450, 100)}
            alt={item.image.productName}
            className={classes.image}
          />
          <div style={{ height: "100px" }} class={classes.editImagePlaceholder}>
            <div>
              <Edit onClick={() => changeImageAction("image", item)} />
            </div>
          </div>
        </div>

        <div className={classes.headerContainer}>
          <Typography variant="h6">
            {item.aliasForLanguage || item.alias}{" "}
          </Typography>

          <DropdownMenu
            item={item}
            showPickupRequest={showPickupRequest}
            changeImageAction={changeImageAction}
            onEventRequest={() => onEventRequest("drop down")}
            iconStyle={{ paddingTop: 0, paddingBottom: 0 }}
            saleRequestInitializedEvent={saleRequestInitializedEvent}
          />
        </div>
        {item.showAddressLabel && item.pickUpFullAddress.numOfAddresses > 1 && (
          <div className={classes.labelContainer}>
            <AddressLabel
              postCode={item.pickUpFullAddress.zipCode}
              city={item.pickUpFullAddress.city}
              isMobile={true}
            />
          </div>
        )}
        <div className={classes.pickUpStatusContainer}>
          {item.statusId !== 1 ? (
            renderPickUpStatus(item.statusId, pickUpStatus, classes)
          ) : (
            <PickUpStatus
              pickups={item.pickups}
              productId={item.old_id}
              contractNumber={item.contractNumber}
            />
          )}
        </div>

        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {getTitle(t, item)}
            </Typography>
            {`${item.collectedQty || 0} / ${item.totalQuantity || item.quantity || 0} mt`}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Price")}
            </Typography>
            {renderPriceValue(item, { parseCurrency })}
          </Grid>
        </Grid>

        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Incoterms")}
            </Typography>
            {item.incoterms || "-"}
          </Grid>
          <Grid item xs={6}>
            {item.statusId === 1 ? (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Materials_NextPickupDate")}
                </Typography>
                <NextPickUpContent item={item} />
              </>
            ) : (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Price Validity Date")}
                </Typography>
                {(item.validTo && parseDate(item.validTo)) || "-"}
              </>
            )}
          </Grid>
        </Grid>

        <div className={classes.buttonContainer}>
          <Button
            onClick={() => setExpanded(true)}
            color="primary"
            variant="text"
          >
            {t("View More")}
          </Button>
          {item.statusId !== 2 && showPickupRequest && (
            <Button
              color="primary"
              onClick={() => onEventRequest("button")}
              variant={item.statusId === 4 ? "outlined" : "contained"}
            >
              {item.statusId === 1 ? t("Request Pickup") : t("Sale Request")}
            </Button>
          )}
        </div>
      </div>
      {/* show existing pickup modals */}
      {data?.RequestPickUpInfo?.pickup?._id ? (
        <ExistingPickUpModal
          pickupStatusCondition={data?.RequestPickUpInfo?.pickupStatusCondition}
          pickupId={data?.RequestPickUpInfo?.pickup?._id}
          item={data?.RequestPickUpInfo?.pickup}
          contractStartDate={moment(item?.contractStartDate).format(
            "YYYY-MM-DD"
          )}
          contractEndDate={moment(item?.contractEndDate).format("YYYY-MM-DD")}
          productId={item.old_id}
          conditionId={item.conditionId}
          open={hasExistingPickups}
          onClose={() => setHasExistingPickups(false)}
          {...item?.pickups} // confirmation_required, pre_scheduled, confirmed,
        />
      ) : (
        <> </>
      )}
    </>
  );
};

export default MobileProductCard;
