import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateDropdownSelector from "./DateDropdownSelector";
import DateDropdownDateRangePicker from "./DateDropdownDateRangePicker";
const getDate = numOfDays => {
  const date = new Date();

  date.setDate(date.getDate() + numOfDays);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${day < 10 ? "0" + day : day}-${
    month < 10 ? "0" + month : month
  }-${year}`;
  return formattedDate;
};

const DateDropdown = ({
  date,
  setDate,
  label,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  disabled = false
}) => {
  const useStyles = makeStyles(theme => ({
    container: { [theme.breakpoints.down("sm")]: { width: "100%" } }
  }));

  const { t } = useTranslation();
  const classes = useStyles();
  const [openDataPickerSelector, setOpenDataPickerSelector] = useState(false);
  const [openDataRangeSelector, setOpenDataRangeSelector] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dateOptions, setDateOptions] = useState([
    { value: "all", label: "All", start: null, end: null },
    {
      value: "week",
      label: t("Pickups_WithinOneWeek"),
      start: getDate(0),
      end: getDate(6)
    },
    {
      value: "2weeks",
      label: t("Pickups_WithinTwoWeeks"),
      start: getDate(0),
      end: getDate(13)
    },
    {
      value: "dateRange",
      label: t("Pickups_SelectADateRange"),
      keepDropdownOpen: true,
      callback: () => {
        setOpenDataRangeSelector(true);
      },
      start: date === "dateRange" ? startDate : null,
      end: date === "dateRange" ? endDate : null
    },
    {
      value: "date",
      label: t("Pickups_SelectADate"),
      keepDropdownOpen: true,
      callback: () => {
        setOpenDataRangeSelector(true);
      },
      start: date === "date" ? startDate : null,
      end: date === "date" ? endDate : null
    }
  ]);

  const updateDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const updateParameter = (parameter, startNewValue, endNewValue) => {
    setStartDate(startNewValue);
    setEndDate(endNewValue);

    setDateOptions(prevOptions => {
      const updatedOptions = [...prevOptions];
      const dateRangeOption = updatedOptions.find(
        option => option.value === parameter
      );
      if (dateRangeOption) {
        dateRangeOption["start"] = startNewValue;
        dateRangeOption["end"] = endNewValue;
      }
      return updatedOptions;
    });
  };
  const resetStartEndDate = () => {
    setTempEndDate(null);
    setTempStartDate(null);

    setDateOptions(prevOptions => {
      const updatedOptions = [...prevOptions];
      const dateOption = updatedOptions.find(option => option.value === "date");

      if (dateOption) {
        dateOption["start"] = null;
        dateOption["end"] = null;
      }
      return updatedOptions;
    });
    updateParameter("dateRange", null, null);

    setDate("all");
  };

  // reset all dates seleted when clicked on clear filters
  useEffect(() => {
    if (date === "all" && startDate === null && endDate === null) {
      resetStartEndDate();
    }
  }, [date, startDate, endDate]);

  return (
    <div className={classes.container}>
      <DateDropdownSelector
        label={label}
        date={date}
        setDate={setDate}
        dateOptions={dateOptions}
        resetStartEndDate={resetStartEndDate}
        setOpenDataRangeSelector={setOpenDataRangeSelector}
        setOpenDataPickerSelector={setOpenDataPickerSelector}
        updateDates={updateDates}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
      />

      <DateDropdownDateRangePicker
        type={date}
        setDate={setDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        open={openDataRangeSelector}
        setOpen={setOpenDataRangeSelector}
        tempStartDate={tempStartDate}
        setTempStartDate={setTempStartDate}
        tempEndDate={tempEndDate}
        setTempEndDate={setTempEndDate}
        dateOptions={dateOptions}
        setDateOptions={setDateOptions}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default DateDropdown;
