import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogContent-root": {
      flex: "1 1 auto",
      minHeight: "343px",
      [theme.breakpoints.down("sm")]: {
        minHeight: "none",
        flex: "none"
      }
    }
  },
  title: {
    display: "flex",
    gap: "10px",
    "&.MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0"
    }
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      gap: 0
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      "&.fullScreen": {
        minHeight: "56px",
        flexBasis: "100%"
      }
    }
  },
  advanced: {
    "& .MuiDialogActions-root": {
      padding: "40px 24px 24px 24px"
    },
    "& .MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: "16px"
    }
  },
  headerRoot: {
    paddingTop: "40px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      paddingTop: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingBottom: "8px",
      position: "relative"
    },
    "&.advanced": {
      paddingTop: "16px",
      paddingBottom: "0"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px",
        position: "absolute",
        top: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "-5px"
      }
    }
  }
}));

const CustomDialog = ({
  open,
  onClose,
  renderTitle,
  title,
  titleRightContent,
  children,
  rootClassName = "",
  PaperProps = {},
  mode, // standard or advanced
  disableClose = false,
  ...params
}) => {
  const classes = useStyles();

  const onClick = disableClose ? () => {} : onClose;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`${rootClassName} ${
        mode === "advanced" ? classes.advanced : ""
      } ${classes.root}`}
      PaperProps={{ ...PaperProps, square: mode === "advanced" }}
      {...params}
    >
      <div
        className={`dialog-title ${classes.headerRoot} ${
          mode === "advanced" ? "advanced" : ""
        }`}
      >
        {renderTitle ? (
          renderTitle
        ) : (
          <div className={classes.flexContainer}>
            <div className={fullScreen && "fullScreen"}>
              <IconButton aria-label="close" onClick={onClick}>
                <ClearIcon />
              </IconButton>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </div>
            {titleRightContent && (
              <div className="rightContent">{titleRightContent}</div>
            )}
          </div>
        )}
      </div>
      {children}
    </Dialog>
  );
};

export default CustomDialog;
