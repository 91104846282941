/* eslint-disable indent */
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  FormControl,
  Button,
  Hidden,
  FormHelperText
} from "@material-ui/core";
import { Close, LocalShippingOutlined } from "@material-ui/icons";
import { useMutation, useQuery } from "react-apollo";

import GetPickupsData from "../../queries/GetPickupsData";

import UserContext from "../../providers/UserProvider";
import { ReschedulePickup as ReschedulePickupMutation } from "../../mutations/pickupsMutations";
import { showError, showSuccess, showInfo } from "../core/shared/Notify";
import Loader from "../core/shared/Loader";
import GetSinglePickupDataById from "../../queries/GetSinglePickupDataById";
import DateDropdownSLA from "../general/DateDropdownSLA";
import Sla from "../core/shared/Sla";
import FileList from "../core/shared/FileList";
import AddIcon from "@material-ui/icons/Add";
import UploadDialog from "../core/shared/uploadDialog";
import useEventTracking from "../../hooks/useEventTracking";
import { useValidation } from "../../hooks/useValidation";
import useStandardLocalization from "../../hooks/useStandardLocalization";
import InformationalComponent from "../general/InformationalComponent";
import { useProductFruitsSurvey } from "../../hooks/useProductFruitsSurvey";
import hasInjectionAttack from "../../utils/checkInjectionAttack";

const ReschedulePickup = ({ pickupId }) => {
  const [dateOptions, setDateOptions] = useState("");
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { startSurvey } = useProductFruitsSurvey();
  const useStyles = makeStyles(theme => ({
    subtitle: {
      color: "rgba(0, 0, 0, 0.6)",
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: 0.4
      }
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 492,
      "& h6": {
        letterSpacing: 0.25
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
        maxHeight: "100%",
        overflowY: "auto",
        zIndex: 100,
        background: "#FFFFFF",
        padding: "0 16px 30px"
      }
    },
    headerContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        gap: 16,
        paddingTop: 16
      }
    },
    pickupContainer: {
      padding: 20,
      background: "#F9F9F9",
      border: "1px solid #E0E0E0",
      borderRadius: 4,
      display: "flex",
      position: "relative",
      gap: 16,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    contractedBar: {
      padding: "1px 4px",
      position: "absolute",
      transform: "translate(-20px, 9px)",
      background: "#52F597",
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: 1.5,
      lineHeight: "16px",
      textTransform: "uppercase"
    },
    imgContainer: {
      minWidth: 100,
      maxWidth: 100,
      maxHeight: "100%",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        height: 100
      },
      "& > img": {
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          maxWidth: 450
        }
      }
    },
    dataContainer: {
      gap: 12,
      [theme.breakpoints.down("sm")]: {
        gap: 8
      }
    },
    nameContainer: {
      marginTop: "8px",
      "& .MuiSvgIcon-root": {
        marginRight: 4
      },
      gap: 12,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        marginTop: 0,
        marginBottom: 4
      }
    },
    textInput: {
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9E9E9E"
      }
    },
    dropdownInput: {
      width: "100%",
      maxWidth: 236,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9E9E9E"
      }
    },
    dateOptionsGrid: {
      maxWidth: dateOptions !== ("dateRange" || "date") && "calc(50% - 10px)"
    },
    datePicker: {
      width: "100%",
      height: 40,
      border: "1px solid #9E9E9E",
      paddingRight: "15px",
      cursor: "pointer",
      "& > input": {
        padding: "10px 0 10px 16px",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: 0.15,
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.87)"
      }
    },
    datePickerEmpty: {
      width: "100%",
      height: 40,
      border: "1px solid #9E9E9E",
      paddingRight: "15px",
      cursor: "pointer",
      "& > input": {
        color: "#BCBCBC",
        padding: "10px 0 10px 16px",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: 0.15
      },
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.87)"
      }
    },
    rangePicker: {
      width: "100%"
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    },
    loaderContainer: {
      width: "100%",
      height: 600,
      [theme.breakpoints.down("sm")]: {
        height: 300
      }
    },
    textField: {
      height: "104px",
      alignItems: "baseline",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    attachedDocsTitle: {
      "font-family": "Roboto",
      "font-style": "normal",
      "font-weight": 500,
      "font-size": "16px",
      "line-height": "24px",
      /* identical to box height, or 150% */

      display: "flex",
      "align-items": "center",
      "letter-spacing": "0.25px",

      /* On surface/High emphasis */

      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "8px"
    },
    pickupDateContainer: {
      color: "rgba(0, 0, 0, 0.38)"
    },
    infoCard: {
      marginTop: "10px",
      marginBottom: "10px"
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { user, selectedCompany, userData } = useContext(UserContext);
  const { TrackEvent } = useEventTracking();
  const { location } = useLocation();
  const [newQuantity, setQuantity] = useState(null);
  const changeQuantity = event => {
    setQuantity(event.target.value);
  };
  const [comments, setComments] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [internalTransactionId, setInternalTransactionId] = useState(null);
  const changeInternalTransactionId = event => {
    const value = event.target.value;

    if (hasInjectionAttack(value)) {
      showError(t("error_injection_attack"));
      return;
    }
    setInternalTransactionId(value);
  };

  const { errors, checkValidation, setErrors } = useValidation();

  const { parseDate } = useStandardLocalization();

  const [reschedulePickup, { loading }] = useMutation(
    ReschedulePickupMutation,
    {
      refetchQueries: () => [
        {
          query: GetPickupsData,
          variables: { token: user?.token, partnerId: selectedCompany }
        }
      ]
    }
  );

  const pickupsQueryVariables = {
    token: user?.token,
    pickupId: pickupId,
    partnerId: selectedCompany === null ? "all" : selectedCompany,
    reasonOfThePetition: "reschedule"
  };

  const {
    loading: loading_single_pickup,
    data: pickupQueryData,
    error: pickupQueryError,
    refetch
  } = useQuery(GetSinglePickupDataById, {
    variables: pickupsQueryVariables,
    fetchPolicy: "cache-and-network"
  });

  const handleRefetch = () => {
    refetch();
  };

  if (loading || loading_single_pickup)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );

  if (!pickupId) return null;

  if (pickupQueryError) {
    const errorMessage = pickupQueryError.graphQLErrors[0].message.substring(
      1,
      pickupQueryError.graphQLErrors[0].message.length - 1
    );

    if (errorMessage === "Error_PickupAlreadyConfirmed") {
      showInfo(t(errorMessage, { pickupId }));
      return <Redirect to={"/pickups"} />;
    }
    if (errorMessage === "Error_PickupNotFound") {
      showError(t(errorMessage, { pickupId }));
      return <Redirect to={"/pickups"} />;
    }
    if (errorMessage === "Error_PickupTooCloseToReschedule") {
      showError(t(errorMessage));
      return <Redirect to={"/pickups"} />;
    }
  }

  const data = pickupQueryData.SinglePickupDataById;
  const { RawUserData } = userData;
  const inFuture = new Date() < new Date(data?.contractStartDate);

  // redirect to from if no canReschedulePickup
  if (data.quantity && !newQuantity) setQuantity(data.quantity);

  if (data.internalTransactionId && internalTransactionId === null)
    setInternalTransactionId(data.internalTransactionId);

  const slaLevel = data.slaLevel;
  const id = data.transactionId;
  const files = data.documents;
  const pickUpDateFormatted = parseDate(data.pickupOrderDate);

  const onSubmit = () => {
    const errors = checkValidation({ date, newQuantity });
    if (errors) return;
    let pickUpDate;

    if (
      (date === "date" || date === "immediately") &&
      startDate &&
      endDate &&
      startDate === endDate
    ) {
      pickUpDate = startDate;
    }

    let collectionType =
      date === "date" || date === "immediately"
        ? "date"
        : date === "dateRange"
          ? "dateRange"
          : date === "2weeks"
            ? "within2Weeks"
            : null;

    if (
      (date === "date" && !pickUpDate) ||
      (date === "dateRange" && (!startDate || !endDate))
    ) {
      setErrors({ date: true });
      return;
    }

    const dateRange = `${startDate} - ${endDate}`;
    reschedulePickup({
      variables: {
        token: user?.token,
        pickupId: data._id,
        internalTransactionId,
        quantity: newQuantity,
        collectionType: collectionType,
        date: pickUpDate,
        dateRange: collectionType === "dateRange" ? dateRange : null,
        additionalComments: comments
      }
    })
      .then(() => {
        TrackEvent("Reschedule pickup completed", {
          orderId: data._id,
          internalTransactionId,
          clickSource: "Button",
          quantity: newQuantity,
          isQuantityChanged: newQuantity !== data.quantity,
          oldDate: data.pickupOrderDate,
          newDate: pickUpDate || startDate,
          userName: RawUserData.firstName + " " + RawUserData.lastName.AddIcon,
          isApproximated: collectionType !== "date"
        });
        //Hotfix, we show only it instead of showing all and the new one on top
        history.push(
          `/pickups?status=all&expanded=${data.transactionId}&showExpandedFirst=true`,
          {
            background: location
          }
        );
        showSuccess(
          t("FeedbackNotification_PickupRescheduleSent", {
            transaction_id: data.transactionId
          })
        );
        startSurvey("PICKUP_REQUEST");
      })
      .catch(err => showError(err.message));
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={() => history.goBack()} size="small" />
        </Hidden>
        <Typography variant="h6">{t("Pickups_ReschedulePickup")}</Typography>
      </div>
      <div className={classes.pickupContainer}>
        {data.isContracted && (
          <div className={classes.contractedBar}>{t("CONTRACTED")}</div>
        )}
        <div className={classes.imgContainer}>
          <img src={data.imageSrc} width="100%" height="100%" alt="metal" />
        </div>
        <Grid container direction="column" className={classes.dataContainer}>
          <Grid item container className={classes.nameContainer}>
            <Grid item>
              <Typography variant="h6">
                {data.aliasForLanguage || data.pickupName}
              </Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              className={classes.pickupDateContainer}
            >
              <LocalShippingOutlined />
              <Typography variant="h6">{pickUpDateFormatted}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <Typography variant="body2" className={classes.subtitle}>
                {t("PICKUP ADDRESS")}
              </Typography>
              {data.companyName}
              <br />
              {data.streetAddress}, {data.zipCode} {data.city} {data.country}
            </div>
          </Grid>
          <Grid item>
            <div style={{ overflowWrap: "anywhere" }}>
              <Typography variant="body2" className={classes.subtitle}>
                {t("Pickups_OrderNumber")}
              </Typography>
              {data.transactionId}
              {data.internalTransactionId && (
                <> / {data.internalTransactionId}</>
              )}
            </div>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={6} xs={6}>
              <Typography variant="body2" className={classes.subtitle}>
                {t("Products_Packaging")}
              </Typography>
              {t(data.packaging) || "-"}
            </Grid>
            <Grid item md={6} xs={6}>
              <Sla level={data.slaLevel} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {inFuture && (
        <InformationalComponent type="info" className={classes.infoCard}>
          {t("contract_startDate_inFuture_message")}
        </InformationalComponent>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.textInput}
            id="quantity"
            label={t("Declared Quantity (kg)")}
            variant="outlined"
            defaultValue={data.quantity}
            onChange={changeQuantity}
            error={errors?.newQuantity}
            helperText={errors?.newQuantity && t("Required")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.dropdownInput}>
            <DateDropdownSLA
              date={date}
              setDate={setDate}
              label={"Pick up Date"}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              slaLevel={slaLevel}
              contractEndDate={data?.contractEndDate}
              contractStartDate={data?.contractStartDate}
            />
            {errors?.date && (
              <FormHelperText error>{t("Required")}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-textarea"
            label={t("Placeholder_InternalTransactionIdTextfield")}
            value={internalTransactionId}
            onChange={changeInternalTransactionId}
            name="internalTransactionId"
            variant="outlined"
            className={classes.textInput}
          />
        </Grid>
      </Grid>
      <TextField
        multiline
        maxRows={4}
        variant="outlined"
        label={t("Additional Comments")}
        InputProps={{ className: classes.textField }}
        fullWidth
        value={comments}
        onChange={event => setComments(event.target.value)}
      />
      {files.length > 0 && (
        <div>
          <Typography className={classes.attachedDocsTitle}>
            {t("PickupModal_AttachedDocuments")}
          </Typography>
          <FileList files={files} orderId={id} />
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Hidden smDown>
          <Button
            onClick={() => {
              history.goBack();
              startSurvey("PICKUP_REQUEST");
            }}
            color="primary"
            variant="text"
          >
            {t("Cancel")}
          </Button>
        </Hidden>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.buttonDesktop}
          onClick={() => {
            TrackEvent("Upload document flow started", {
              orderId: data._id,
              clickSource: "Button"
            });
            setUploadDialogOpen(true);
          }}
        >
          {t("DOCUMENTS")}
        </Button>
        <Button color="primary" variant="contained" onClick={onSubmit}>
          {t("Products_Request")}
        </Button>
      </div>
      {uploadDialogOpen ? (
        <UploadDialog
          orderId={pickupId}
          transactionId={data.transactionId}
          internalTransactionId={data.internalTransactionId}
          orderType="sale"
          onClose={() => {
            handleRefetch();
            setUploadDialogOpen(false);
          }}
          seller={true}
          disableInvoiceUpload={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReschedulePickup;
