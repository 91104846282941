import gql from "graphql-tag";

const ConfirmPickup = gql`
  mutation confirmPickup(
    $token: String!
    $pickupId: String!
    $quantity: Float!
    $additionalComments: String
  ) {
    confirmPickup(
      token: $token
      pickupId: $pickupId
      quantity: $quantity
      additionalComments: $additionalComments
    ) {
      message
    }
  }
`;

const ReschedulePickup = gql`
  mutation reschedulePickup(
    $token: String!
    $pickupId: String!
    $internalTransactionId: String
    $quantity: Float!
    $collectionType: String!
    $date: String
    $dateRange: String
    $additionalComments: String
  ) {
    reschedulePickup(
      token: $token
      pickupId: $pickupId
      internalTransactionId: $internalTransactionId
      quantity: $quantity
      collectionType: $collectionType
      date: $date
      dateRange: $dateRange
      additionalComments: $additionalComments
    ) {
      message
    }
  }
`;

const RequestPickup = gql`
  mutation requestPickup(
    $token: String!
    $conditionId: String!
    $quantity: Float!
    $collectionType: String!
    $packaging: String!
    $date: String
    $dateRange: String
    $additionalComments: String
    $files: [Upload]
    $metas: [UploadFileToOrderMetaInput]
    $internalTransactionId: String
    $selectedUser: String
  ) {
    requestPickup(
      token: $token
      conditionId: $conditionId
      quantity: $quantity
      collectionType: $collectionType
      packaging: $packaging
      date: $date
      dateRange: $dateRange
      additionalComments: $additionalComments
      files: $files
      metas: $metas
      internalTransactionId: $internalTransactionId
      selectedUser: $selectedUser
    ) {
      message
      operatorEmail
      traderEmail
      pickup {
        _id
        transactionId
        internalTransactionId
        productId
        pickupName
        companyName
        streetAddress
        zipCode
        city
        country
        pickupDate
        quantity
        items
        truckType
        truckNumberPlate
        imageSrc
        isContracted
        files
        packaging
        collectionType
        collectionDate
        collectionDateRange
        statusId
        status
        canConfirmPickup
        canReschedulePickup
        slaLevel
        new
        aliasForLanguage
      }
      pickups {
        confirmation_required
        pre_scheduled
        confirmed
      }
    }
  }
`;

export { ConfirmPickup, ReschedulePickup, RequestPickup };
