import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ReactComponent as AppIcon } from "../../../assets/appIcon.svg";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import StyledTooltip from "../../core/StyledTooltip";
const DropdownMenu = ({
  item,
  onEventRequest,
  iconStyle = {},
  showPickupRequest,
  changeImageAction,
  saleRequestInitializedEvent
}) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const useStyles = makeStyles({
    menuItem: {
      padding: "12px 28px 12px 16px",
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    tooltip: {
      borderRadius: "0%",
      backgroundColor: "black",
      color: "white",
      fontSize: 12,
      padding: "8px 12px",
      "& .MuiTooltip-arrow": {
        color: "black"
      }
    }
  });

  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledTooltip
        text={t("tooltip_click_for_more_options")}
        arrow
        placement={"left"}
        style={{ tooltip: classes.tooltip }}
      >
        <IconButton
          size="medium"
          onClick={event => setAnchorEl(event.currentTarget)}
          aria-controls="menu"
          style={{ ...iconStyle, borderRadius: 0 }}
        >
          <AppIcon />
        </IconButton>
      </StyledTooltip>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            saleRequestInitializedEvent("drop down", item);
            history.push(`/products?sale-request=${item.productId}`);
          }}
          className={classes.menuItem}
        >
          {t("Request Sale")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onEventRequest("drop down");
          }}
          className={classes.menuItem}
          disabled={!showPickupRequest || [4, 2, 3].includes(item.statusId)}
        >
          {t("Request Pickup")}
        </MenuItem>
        <MenuItem
          disabled={
            item.pickups.confirmed === 0 &&
            item.pickups.confirmation_requried === 0 &&
            item.pickups.pre_scheduled === 0
          }
          onClick={() => {
            handleClose();
            history.push(`/pickups?status=all`);
          }}
          className={classes.menuItem}
        >
          {t("Show Pickups")}
        </MenuItem>
        <MenuItem
          disabled={item.hasOpenSales !== true}
          onClick={() => {
            handleClose();
            item.productId
              ? history.push(`/sales?status=all&product=${item.productId}`)
              : history.push("/sales?status=all");
          }}
          className={classes.menuItem}
        >
          {t("Show Sales")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            changeImageAction("dropdown", item);
          }}
          className={classes.menuItem}
        >
          {t("Change Image")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default DropdownMenu;
