import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  makeStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useQuery } from "react-apollo";
import queryString from "query-string";

import AddressLabel from "../general/label/address";
import UserContext from "../../providers/UserProvider";
import PickupRequestForm from "./PickUpRequestForm";
import Loader from "../core/shared/Loader";

import { useProductFruitsSurvey } from "../../hooks/useProductFruitsSurvey";
import GetAllStorageTypes from "../../queries/GetAllStorageTypes";
import { GET_CONTRACTED_INFO } from "../../queries/Products";
import { showError } from "../core/shared/Notify";
import CompanyUserQuery from "../../queries/CompanyUserQuery";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useEventTracking from "../../hooks/useEventTracking";
import { SelectMenuProps } from "../core/shared/constants";
import InformationalComponent from "../general/InformationalComponent";
import PickUpPanel from "./PickupsCard/shared/PickUpPanel";
import Sla from "../core/shared/Sla";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "492px",
    background: "#fff"
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  top: {
    padding: "0 0 20px",
    display: "flex",
    alignItems: "center"
  },
  topIcon: {
    display: "none"
  },
  cardRoot: {
    position: "relative",
    fontFamily: "Roboto",
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    boxSizing: "border-box",
    marginBottom: "24px",
    "&  *, &  *:before, & *:after": {
      boxSizing: "border-box"
    }
  },
  MuiCardContentRoot: {
    padding: "20px",
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      gap: "0px"
    }
  },
  imgWrap: {
    flex: "0 0 100px",
    margin: "0",
    minWidth: 100,
    maxWidth: 100,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      height: 100,
      objectFit: "cover"
    },
    "& > img": {
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: 450
      }
    }
  },
  pickUpBadge: {
    padding: "1px 4px 0 4px",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    position: "absolute",
    top: "28px",
    left: "0"
  },
  labelContainer: {
    width: "fit-content"
  },
  titleAndLabelContainer: {
    display: "flex",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "10px"
    }
  },
  textWrap: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "8px",
    flexGrow: 1
  },
  pickupName: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0 0 12px",
    maxWidth: 325
  },
  orderNumber: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    margin: "0",
    color: "rgba(0, 0, 0, 0.6)"
  },
  orderNumberValue: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  gridCol: {
    marginBottom: "20px"
  },
  formControl: {
    width: "100%",
    margin: 0,
    "& .MuiIconButton-root": {
      padding: 0
    }
  },
  packagingAndSlaContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "end",
    gap: "16px",
    "& > div": {
      flexGrow: 1,
      padding: 0
    }
  },
  [theme.breakpoints.down("md")]: {
    container: {
      maxWidth: "fit-content"
    },
    top: {
      padding: "0 0 16px"
    },
    imgWrap: {
      maxWidth: "100%",
      flex: "0 0 100%"
    },
    orderNumber: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px"
    }
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      position: "absolute",
      width: "100%",
      maxWidth: "100%",
      left: "0",
      top: "0",
      zIndex: "1000",
      height: "100%",
      padding: "16px",
      display: "flex",
      flexDirection: "column"
    },
    topIcon: {
      display: "block",
      marginRight: "16px"
    },
    insideDiv: {
      overflowY: "auto",
      overflowX: "hidden",
      flex: "auto"
    }
  },
  loaderContainer: {
    width: "100%",
    height: 600,
    [theme.breakpoints.down("sm")]: {
      height: 300
    }
  },
  infoCard: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  padding0: {
    padding: 0
  }
}));

const RequestPickUp = ({ id, contractedData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const { startSurvey } = useProductFruitsSurvey();
  const { TrackEvent } = useEventTracking();
  const { user, selectedCompany, userData } = useContext(UserContext);
  const isAdmin = userData.RawUserData.isAdmin;
  const { state: { background } = {} } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(null);
  const observedDiv = useRef(null);
  const titleDiv = useRef(null);
  const [height, setHeight] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageHeight, setImageHeight] = useState(isMobile ? "100px" : height);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [requiredError, setRequiredError] = useState(false);
  const parsedQueryString = queryString.parse(location.search);
  const manyParam = parsedQueryString.many;
  const URL_PARAM_FALSE = manyParam === "false";
  const URL_PARAM_TRUE = manyParam === "true";
  const UNKOWN_CONTRACTED_MATERIAL_ID = "----";

  if ((id === UNKOWN_CONTRACTED_MATERIAL_ID && URL_PARAM_FALSE) || !id)
    return null;
  const {
    loading: packagingLoading,
    data: { allStorageTypes: packaging } = {}
  } = useQuery(GetAllStorageTypes, {
    variables: { token: user.token },
    fetchPolicy: "cache-and-network"
  });

  const { error, loading, data } =
    id !== UNKOWN_CONTRACTED_MATERIAL_ID
      ? useQuery(GET_CONTRACTED_INFO, {
          variables: {
            token: user?.token,
            contractId: id && id.toString()
          },
          fetchPolicy: "network-only",
          onCompleted: () => {
            if (!width && observedDiv.current)
              setWidth(observedDiv.current.offsetWidth);
          }
        })
      : useQuery(GetAllStorageTypes, {
          variables: { token: user.token },
          fetchPolicy: "cache-and-network"
        });

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers
  } = useQuery(CompanyUserQuery, {
    variables: {
      token: user.token,
      company: selectedCompany,
      avoidAdminUsers: true
    },
    fetchPolicy: "network-only",
    enabled: isAdmin,
    onCompleted: () => {
      if (!width && observedDiv.current)
        setWidth(observedDiv.current.offsetWidth);
    }
  });

  if (loading || packagingLoading || isLoading || loadingUsers)
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );

  if (error) {
    const errorMessage = error.graphQLErrors[0]?.message;
    showError(t(errorMessage));
    return <Redirect to={"/products"} />;
  }

  if (errorUsers) {
    const errorMessage = errorUsers.graphQLErrors[0]?.message;
    showError(t(errorMessage));
    return <Redirect to={"/products"} />;
  }

  let result, userArray;
  if (id === UNKOWN_CONTRACTED_MATERIAL_ID && URL_PARAM_TRUE) {
    result = {
      alias: "",
      aliasForLanguage: "",
      contractStartDate: "",
      contractEndDate: "",
      fullPickUpAddress: null,
      id: null,
      imageSrc: "",
      packaging: "",
      quantity: null,
      slaLevel: "",
      __typename: ""
    };
    userArray = [];
  } else if (id !== UNKOWN_CONTRACTED_MATERIAL_ID) {
    result = data.GetConditionInfo;
    userArray = dataUsers?.CompanyUsers?.companyUsersArray || [];
  }

  let width_for_container;
  let showAddressBelow = false;

  if (width) {
    width_for_container = 20 + 100 + 12 + width + 24 + 15;
    showAddressBelow = width_for_container > 492 && width !== null;
    if (!imageHeight && observedDiv.current) {
      let newHeight = titleDiv.current.offsetHeight + 12 + 20 + 17;
      newHeight = showAddressBelow ? newHeight + 36 : newHeight;
      setHeight(newHeight > 100 ? newHeight : 100);
      setImageHeight(newHeight > 100 ? newHeight : 100);
    }
  }

  const inFuture = new Date() < new Date(result?.contractStartDate);

  const packagingLabel =
    packaging.find(c => c.id === result.packaging)?.name || "";

  const filteredContractedData = contractedData.filter(
    contract => new Date(contract?.contractEndDate) > new Date()
  );
  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <Close
          onClick={() => {
            startSurvey("PICKUP_REQUEST");
            history.goBack();
          }}
          size="small"
          className={classes.topIcon}
        />

        <h3 className={classes.title}>{t("Products_RequestPickup")}</h3>
      </div>
      <Card className={classes.cardRoot} variant="outlined">
        <CardContent className={classes.MuiCardContentRoot}>
          <figure
            className={classes.imgWrap}
            style={{
              height: imageHeight
            }}
          >
            {result.imageSrc === "" ? (
              <img
                src="https://images.scrap24.com/-100x148-2v3Rm2xiAycWyiLqj.png"
                width="100px"
                height="100px"
                alt="metal"
              />
            ) : (
              <img
                src={result.imageSrc}
                width="100%"
                height="100%"
                alt="metal"
              />
            )}

            {result.isContracted ? (
              <div className={classes.pickUpBadge}>{t("CONTRACTED")}</div>
            ) : null}
          </figure>
          <div className={classes.textWrap} ref={observedDiv}>
            <div className={classes.titleAndLabelContainer} ref={titleDiv}>
              <h3 className={classes.pickupName}>
                {result.aliasForLanguage || result.alias}
              </h3>
              {result &&
              result.fullPickUpAddress !== null &&
              result.fullPickUpAddress !== undefined &&
              result.fullPickUpAddress.numOfAddresses > 1 &&
              !showAddressBelow ? (
                <div className={classes.labelContainer}>
                  <AddressLabel
                    postCode={result.fullPickUpAddress.zipCode}
                    city={result.fullPickUpAddress.city}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {result &&
            result.fullPickUpAddress !== null &&
            result.fullPickUpAddress !== undefined &&
            result.fullPickUpAddress.numOfAddresses > 1 &&
            showAddressBelow ? (
              <div
                style={{ marginBottom: 12 }}
                className={classes.labelContainer}
              >
                <AddressLabel
                  postCode={result.fullPickUpAddress.zipCode}
                  city={result.fullPickUpAddress.city}
                />
              </div>
            ) : (
              <div
                className={classes.labelContainer}
                style={{ lineHeight: 16 }}
              ></div>
            )}
            <div className={classes.packagingAndSlaContainer}>
              <PickUpPanel
                className={classes.padding0}
                title={t("Products_Packaging")}
                variant="full"
                description={t(packagingLabel) || "-"}
              />
              <Sla level={result.slaLevel} />
            </div>
          </div>
        </CardContent>
      </Card>
      {URL_PARAM_TRUE && (
        <div>
          <Grid item xs={12} md={30} className={classes.gridCol}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="material-dropdown">
                {t("choose_your_material")}
              </InputLabel>
              <Select
                id="companyMaterial"
                label={t("choose_your_material")}
                value={selectedMaterial}
                onChange={event => setSelectedMaterial(event.target.value)}
                name="SelectMaterial"
                MenuProps={SelectMenuProps}
                error={requiredError}
              >
                {filteredContractedData.map(item => (
                  <MenuItem
                    key={item.conditionId}
                    value={
                      item.aliasForLanguage ? item.aliasForLanguage : item.alias
                    }
                    onClick={() => {
                      if (requiredError) setRequiredError(false);

                      TrackEvent("Pick up requested", {
                        source: "Choose material dropdown",
                        productId: item.productId,
                        quantity: item.quantity,
                        conditionsId: item.conditionId
                      });
                      history.replace(
                        `/pickups?request=${item.conditionId}&many=true`,
                        { background: location }
                      );
                    }}
                  >
                    {item.aliasForLanguage ? item.aliasForLanguage : item.alias}
                  </MenuItem>
                ))}
              </Select>
              {requiredError && (
                <FormHelperText error>{t("Required")}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </div>
      )}
      {inFuture && (
        <InformationalComponent type="info" className={classes.infoCard}>
          {t("contract_startDate_inFuture_message")}
        </InformationalComponent>
      )}
      <PickupRequestForm
        form={result}
        packaging={packaging}
        setIsLoading={setIsLoading}
        userArray={userArray}
        setRequiredError={setRequiredError}
        fromMultiple={URL_PARAM_TRUE}
      />
    </div>
  );
};

export default RequestPickUp;
