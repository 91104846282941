import React, { useCallback, useEffect, useState } from "react";
import DocPreviewContext from "../../../providers/DocPreviewProvider";
import { makeStyles } from "@material-ui/core";
import { downloadFromUrl, getDownloadUrl } from "../../../utils/browser";
import useQueryParams from "../../../hooks/useQueryParams";
import { useLazyQuery } from "react-apollo";
import GetDocumentQuery from "../../../queries/GetDocument";
import { useHistory, useLocation } from "react-router-dom";
import { showSuccess } from "../../core/shared/Notify";
import DocPreviewModal from "./DocPreviewModal";
import usePDFViewer from "../../../hooks/usePDFViewer";
import useEventTracking from "../../../hooks/useEventTracking";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { isFileImagePdf } from "../../../utils/Format";
import { reportToSlack } from "../../../modules/slack";
import useUserData from "../../../hooks/useUserData";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 20vw)",
      height: "calc(100% - 260px);"
    },
    "& > .dialog-title": {
      paddingBottom: "24px",
      boxShadow: "inset 0px -1px 0px rgb(0 0 0 / 12%)"
    },
    "& > .MuiDialogActions-root": {
      boxShadow: "inset 0px 1px 0px rgba(0, 0, 0, 0.12)"
    }
  },
  imageWrap: {
    position: "absolute",
    overflow: "auto",
    height: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    display: "flex"
  },
  image: ({ imageScale }) => ({
    poasition: "relative",
    margin: "auto",
    maxWidth: imageScale === 1 ? "100%" : "none",
    maxHeight: imageScale === 1 ? "100%" : "none",
    width: imageScale === 1 ? "auto" : `calc((100%) * ${imageScale})`,
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
      maxWidth: "none",
      maxHeight: "none"
    }
  }),
  errorMessageContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "250px",
    transform: "translate(-50%, -50%)"
  }
}));

const DocPreviewProvider = ({
  children,
  token,
  selectedCompany: partnerId
}) => {
  const [imageScale, setImageScale] = useState(1);
  const classes = useStyles({ imageScale });
  const [fromEmail, setFromEmail] = useState(false);
  const [document, setDoc] = useState(null);
  const [page, setPage] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const { _id: userId } = useUserData();
  const { TrackEvent } = useEventTracking();

  const { filename, file, fileType } = getDocumentProps(document);
  const queryParams = useQueryParams();

  const download = queryParams.get("download");

  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const zoomInImage = useCallback(() => {
    setImageScale(imageScale + 0.1);
  }, [imageScale]);

  const zoomOutImage = useCallback(() => {
    setImageScale(imageScale - 0.1);
  }, [imageScale]);

  const resectZoomImage = useCallback(() => {
    setImageScale(1);
  }, []);

  // set up pdf viewer hook
  const {
    currentPage,
    loading,
    totalPages,
    canvasRef,
    pdfCssStyles,
    errorMessage,
    onZoomIn,
    onZoomOut,
    resetZoom,
    onSendEmail
  } = usePDFViewer({
    visible,
    docUrl: file,
    fileType
  });

  // track event function
  const onTrackEvent = (eventName, params) => {
    const { doc, orderId, fromEmail, page } = params;
    const cleanedOrderId = orderId?.replace(/_orders/g, ""); // removes _orders as it's not supopse to appear in events
    if (doc.isUploadPreview) return;

    TrackEvent(eventName, {
      orderId: cleanedOrderId,
      source: fromEmail ? "email" : "pageButton",
      pageName: page ? page : window.location.href,
      documentType: doc ? doc.documentType : null,
      documentName: doc ? doc.documentName : null
    });
  };

  // set document and show/close modal conditionally
  const setDocument = (doc, params = {}) => {
    setDoc(doc);
    if (doc) {
      const { orderId, fromEmail, page } = params;
      const cleanedOrderId = orderId?.replace(/_orders/g, ""); // removes _orders as it's not supopse to appear in events
      // check if file type is supported
      if (isFileImagePdf(doc)) {
        setVisible(true);

        // event from buttons/emails
        onTrackEvent("Transactional document previewed", {
          ...params,
          orderId: cleanedOrderId,
          doc
        });
      } else {
        // download file if not download  already from url/email
        downloadFromUrl(doc.downloadLink, filename);
        //Report on slack
        reportToSlack("documentDownloaded", {
          userId,
          partnerId,
          orderId,
          documentId: doc.id,
          documentType: doc.documentType
        });
        onTrackEvent("Transactional document downloaded", {
          doc,
          orderId: cleanedOrderId,
          fromEmail,
          page
        });
        showSuccess(`${filename} ${t("was successfully downloaded")}`);
      }

      // update states
      setPage(page);
      setFromEmail(fromEmail);
      setOrderId(orderId);
    } else {
      setVisible(false);
    }
  };

  const onClose = () => {
    setVisible(false);
    // reset scale as well
    setImageScale(1);
  };

  const downloadDocument = useCallback(
    (file, filename) => {
      const documentUrl = file;
      const fileName = filename;
      downloadFromUrl(documentUrl, decodeURIComponent(fileName));
      //Report on slack
      reportToSlack("documentDownloaded", {
        userId,
        partnerId,
        orderId,
        documentId: document.id,
        documentType: document.documentType
      });
      // download event from page buttons
      onTrackEvent("Transactional document downloaded", {
        doc: document,
        orderId,
        fromEmail,
        page
      });
      setVisible(false);
      setDoc(null);
    },
    [document, setVisible, setDoc]
  );
  const downloadFile = async () => {
    await wait(3000); // Waits for 3 seconds (3000 milliseconds)
    window.location.href = getDownloadUrl(file);
    //Report on slack
    reportToSlack("documentDownloaded", {
      userId,
      partnerId,
      orderId,
      documentId: document.id,
      documentType: document.documentType
    });
    onTrackEvent("Transactional document downloaded", {
      doc: document,
      orderId,
      fromEmail: true,
      page
    });
    showSuccess(`${filename} ${t("was successfully downloaded")}`);
  };

  // this prevent multiple downlad when component props changes aside the dependencies
  useEffect(() => {
    if (download === "true" && file) {
      downloadFile();
    }
  }, [download, filename, file]);

  return (
    <DocPreviewContext.Provider
      value={{
        document,
        setDocument
      }}
    >
      {children}
      <DocumentLoaderFromQueryParams
        setFromEmail={setFromEmail}
        setDocument={setDocument}
        setPage={setPage}
        setOrderId={setOrderId}
        token={token}
        partnerId={partnerId}
      />
      <DocPreviewModal
        headerTitle={filename}
        visible={visible}
        onClose={onClose}
        currentPage={currentPage || 1}
        totalPages={totalPages || 1}
        onZoomIn={fileType !== "application/pdf" ? zoomInImage : onZoomIn}
        onZoomOut={fileType !== "application/pdf" ? zoomOutImage : onZoomOut}
        resetZoom={fileType !== "application/pdf" ? resectZoomImage : resetZoom}
        onDownLoad={() => downloadDocument(file, filename)}
        onSendEmail={onSendEmail}
        disableEvents={!!errorMessage}
        loading={loading}
      >
        {fileType === "application/pdf" ? (
          <div
            ref={canvasRef}
            className={pdfCssStyles.viewContainer}
            data-closed-modal="true"
          >
            <div
              className={pdfCssStyles.pdfViewer}
              data-closed-modal="true"
            ></div>
            {errorMessage && (
              <div className={classes.errorMessageContainer}>
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.imageWrap} data-closed-modal="true">
            <img src={file} alt={filename} className={classes.image} />
          </div>
        )}
      </DocPreviewModal>
    </DocPreviewContext.Provider>
  );
};

export default DocPreviewProvider;

const DocumentLoaderFromQueryParams = ({
  setDocument,
  token,
  partnerId,
  setFromEmail,
  setOrderId,
  setPage
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const documentId = queryParams.get("documentId");

  const [GetDocument] = useLazyQuery(GetDocumentQuery, {
    variables: {
      token
    },
    fetchPolicy: "network-only",
    onCompleted: ({ GetDocument: document } = {}) => {
      if (document) {
        queryParams.delete("documentId");
        history.replace({
          pathname: location.pathname,
          search: new URLSearchParams(queryParams).toString()
        });

        // view document from email..
        setDocument(document, {
          orderId: "", // part of query params
          fromEmail: true,
          page: window.location.href
        });

        // updates states...
        setOrderId("");
        setFromEmail(true);
        setPage(window.location.href);
        if (queryParams.get("document")) {
          const { filename, file } = getDocumentProps(document);
          downloadFromUrl(file, filename);
        }
      }
    }
  });

  useEffect(() => {
    if (documentId && partnerId !== "all") {
      GetDocument({ variables: { documentId, partnerId } });
    }
  }, [documentId, partnerId, setDocument, setPage, setFromEmail, setOrderId]);

  return null;
};

const getDocumentProps = document => {
  const {
    documentName,
    documentType,
    downloadLink,
    fileType = ""
  } = document || {
    documentName: "",
    documentType: "",
    downloadLink: ""
  };
  return {
    filename: documentName,
    title: documentType || documentName,
    file: downloadLink,
    fileType
  };
};

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
