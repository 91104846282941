import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  Hidden
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CheckboxWithLabel from "../../components/core/CheckboxWithLabel";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { REVERSE_ONBOARDING_STEPS } from "../../utils/constants";
import MobileTwoRowsModule from "./MobileTwoRowsModule";
import TwoColumnsModule from "./TwoColumnsModule";
import queryString from "query-string";
import { useQuery, useMutation } from "react-apollo";
import OnboardingEmail from "../../queries/OnboardingEmail";
import OnboardingWelcome from "../../mutations/OnboardingWelcome";
import Loader from "../../components/core/shared/Loader";
import useEventTracking from "../../hooks/useEventTracking";
import useMetaloopUrl from "../../hooks/useMetaloopUrl";
import { IdentifyEventUser } from "../../modules/segment";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  TextField: {
    marginBottom: "20px",
    width: "400px"
  },
  TextFieldLast: {
    marginBottom: "12px",
    width: "400px"
  },
  checkboxContainer: {
    marginRight: "auto", // This pushes the checkbox to the right
    marginBottom: "12px"
  },
  button: {
    marginLeft: "auto", // This pushes the checkbox to the right
    marginBottom: "20px",
    width: "400px"
  },

  error: {
    marginTop: "-4px",
    marginBottom: "16px"
  },
  hyperLink: {
    color: "#007bff",
    cursor: "pointer"
  },
  errorDiv: {
    marginTop: "-16px",
    marginBottom: "16px"
  },
  [theme.breakpoints.down("sm")]: {
    title: {
      margin: "24px 0 8px",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "28px"
    },
    subtitle: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      fontStyle: "normal",
      letterSpacing: "0.25px",
      color: "black",
      opacity: 0.6,
      marginBottom: "20px"
    },
    TextField: {
      width: "calc(100% - 30px)"
    },
    TextFieldLast: {
      width: "calc(100% - 30px)"
    },
    checkboxContainer: {
      marginLeft: "15px"
    },
    button: {
      width: "calc(100% - 30px)",
      marginLeft: "0px !important"
    }
  }
}));

const GetStarted = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [checkedTC, setCheckedTC] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [languageSet, setLanguageSet] = useState(false);

  const [sendDataMutation] = useMutation(OnboardingWelcome);

  useEffect(() => {
    const emailSource = location.state?.sourceQueryObj?.emailSource;
    TrackEvent("Account creation - Screen loads", {
      source: emailSource || "unknown"
    });
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirm = () => setShowConfirm(!showConfirm);
  const onChangePassword = ev => setPassword(ev.target.value);
  const onChangeConfirm = ev => setConfirm(ev.target.value);

  const token = location.state?.token;
  const sourceQueryObj = location.state?.sourceQueryObj;
  if (!token) {
    history.push(`/`);
  }
  const { loading, data, error } = useQuery(OnboardingEmail, {
    variables: { token },
    fetchPolicy: "network-only"
  });
  if (loading) return <Loader />;

  if (error) {
    history.push(`/`);
    return null;
  }

  const onboardingStart = data.OnboardingInformationQuery.onboardingStart;
  if (!onboardingStart)
    history.push(`/onboarding/${REVERSE_ONBOARDING_STEPS[1]}`, {
      token,
      sourceQueryObj
    });

  if (!loading && !languageSet) {
    if (data.OnboardingInformationQuery.language) {
      i18n.changeLanguage(data.OnboardingInformationQuery.language);
    } else {
      i18n.changeLanguage("de");
    }
    setLanguageSet(true);
  }

  const email = data.OnboardingInformationQuery.email;

  const onSubmit = async () => {
    let errors = {};
    if (password === "") {
      errors = { ...errors, blankPW: true };
    }
    if (password !== confirm) {
      errors = { ...errors, passwords: true };
    }
    if (!checkedTC) {
      errors = { ...errors, checkbox: true };
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const OnboardingWelcomeInput = {
        password: password,
        confirm: confirm,
        termsAndConditions: checkedTC,
        language: i18n.language
      };
      await sendDataMutation({
        variables: {
          token: token,
          input: OnboardingWelcomeInput
        }
      })
        .then(data => {
          TrackEvent("KYC Flow - Lets get started - Next");
          if (data.data.OnboardingWelcomeMutation.success) {
            const userData = data.data.OnboardingWelcomeMutation.userData;
            IdentifyEventUser(userData);
            history.push(`/onboarding/${REVERSE_ONBOARDING_STEPS[1]}`, {
              token,
              sourceQueryObj
            });
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  };

  const headers = {
    rightColumnHeaderText: t("onboarding_get_started_right_header"),
    rightColumnSubHeaderText: t("onboarding_get_started_right_subheader"),
    leftColumnHeaderText: t("onboarding_get_started_header"),
    leftColumnSubHeaderText: t("onboarding_get_started_subheader")
  };

  return (
    <>
      <Hidden smDown>
        <TwoColumnsModule headers={headers}>
          <div className={classes.form}>
            <Form
              email={email}
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              password={password}
              onChangePassword={onChangePassword}
              showConfirm={showConfirm}
              handleClickShowConfirm={handleClickShowConfirm}
              confirm={confirm}
              onChangeConfirm={onChangeConfirm}
              errors={errors}
              checkedTC={checkedTC}
              setCheckedTC={setCheckedTC}
              onSubmit={onSubmit}
            />
          </div>
        </TwoColumnsModule>
      </Hidden>
      <Hidden mdUp>
        <MobileVersion
          email={email}
          handleClickShowPassword={handleClickShowPassword}
          showPassword={showPassword}
          password={password}
          onChangePassword={onChangePassword}
          showConfirm={showConfirm}
          handleClickShowConfirm={handleClickShowConfirm}
          confirm={confirm}
          onChangeConfirm={onChangeConfirm}
          errors={errors}
          checkedTC={checkedTC}
          setCheckedTC={setCheckedTC}
          onSubmit={onSubmit}
          headers={headers}
        />
      </Hidden>
    </>
  );
};

const Form = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    email,
    handleClickShowPassword,
    showPassword,
    password,
    onChangePassword,
    showConfirm,
    handleClickShowConfirm,
    confirm,
    onChangeConfirm,
    errors,
    checkedTC,
    setCheckedTC,
    onSubmit
  } = props;
  const InputProps = [
    {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("Toggle password visibility")}
            onClick={handleClickShowPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
    },
    {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label={t("Toggle confirm password visibility")}
            onClick={handleClickShowConfirm}
          >
            {showConfirm ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )
    }
  ];

  const { metaloopBaseURL } = useMetaloopUrl();

  const openTCs = () => {
    window.open(`${metaloopBaseURL}/terms-and-conditions`, "_blank");
    setCheckedTC(!checkedTC);
  };

  const specialLabel = (
    <>
      {t("onboarding_field_TandC1") + " "}
      <a className={classes.hyperLink} onClick={openTCs}>
        {t("onboarding_field_TandC2")}
      </a>
      {t("onboarding_field_TandC3") !== "onboarding_field_TandC3" &&
        " " + t("onboarding_field_TandC3")}
    </>
  );

  const canContinue = password && confirm && checkedTC;

  return (
    <>
      <TextField
        label={t("onboarding_field_email")}
        variant="outlined"
        fullWidth={true}
        className={classes.TextField}
        value={email}
        disabled={true}
      />
      <TextField
        label={t("Password")}
        variant="outlined"
        fullWidth={true}
        InputProps={InputProps[0]}
        name="password"
        id="adornment-password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={onChangePassword}
        className={classes.TextField}
      />
      {errors.blankPW && (
        <div className={classes.errorDiv}>
          <FormHelperText error>
            {t("onboarding_password_required")}
          </FormHelperText>
        </div>
      )}
      <TextField
        label={t("onboarding_field_confirm_pw")}
        variant="outlined"
        fullWidth={true}
        InputProps={InputProps[1]}
        name="password"
        id="adornment-password"
        type={showConfirm ? "text" : "password"}
        value={confirm}
        onChange={onChangeConfirm}
        className={classes.TextFieldLast}
      />
      {errors.passwords && (
        <FormHelperText error>
          {t("onboarding_validation_not_match")}
        </FormHelperText>
      )}
      <div className={classes.checkboxContainer}>
        <CheckboxWithLabel
          checked={checkedTC}
          specialLabel={specialLabel}
          color="secondary"
          onChange={event => {
            setCheckedTC(event.target.checked);
          }}
        />
      </div>
      {errors.checkbox && (
        <FormHelperText className={classes.error} error>
          {t("Required")}
        </FormHelperText>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onSubmit}
        disabled={!canContinue}
      >
        {t("save")}
      </Button>
    </>
  );
};

const MobileVersion = props => {
  const classes = useStyles();
  const headers = props.headers;
  return (
    <>
      <MobileTwoRowsModule
        headerText={headers.rightColumnHeaderText}
        subHeaderText={headers.rightColumnSubHeaderText}
        fullScroll={true}
      >
        <Typography className={classes.title}>
          {headers.leftColumnHeaderText}
        </Typography>
        <Typography className={classes.subtitle}>
          {" "}
          {headers.leftColumnSubHeaderText}
        </Typography>
        <Form {...props} />
      </MobileTwoRowsModule>
    </>
  );
};

export default GetStarted;
