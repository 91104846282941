import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "react-apollo";
import checkDriverToken from "../../mutations/checkDriverToken";
import { useNotifications } from "../../hooks/Notifications";
import useEventTracking from "../../hooks/useEventTracking";
import Loader from "../../components/core/shared/Loader";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import ThankYouPage from "./ThankYouPage";
import DocPreviewProvider from "../../components/core/shared/DocPreviewProvider";
import { TBD_FILE_TYPE } from "../../components/core/shared/uploadDialog/components/FileList/fileTypeOptions";
import NavBar from "./NavBar";

export default function FileUploadPage({
  accept = "image/*, application/pdf,"
}) {
  const [step, setStep] = useState(null);
  const [files, setFiles] = useState([]);
  const { pushNotification } = useNotifications();
  const [checkDriverTokenMutation] = useMutation(checkDriverToken);
  const [expiresIn, setExpiresIn] = useState(null);
  const [driverTokenValidity, setDriverTokenValidity] = useState(null);
  const { TrackEvent } = useEventTracking();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("t") || urlParams.get("params");
  const firstCheck = useRef(false);
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await checkDriverTokenMutation({
          variables: { token }
        });
        const { valid, message, data } = response.data.checkDriverToken;
        setExpiresIn(data?.expiresIn);
        setDriverTokenValidity({ valid, message, data });
        if (!valid) {
          setStep(5);
        } else if (!firstCheck.current) {
          setStep(1);
          TrackEvent("Upload Weight Note Link Opened", {
            orderId: data?.order?._id,
            partnerId: data?.partner?.partnerId,
            productId: data?.product?.productID,
            clickSource: "Scanned QR code or clicked link"
          });
          firstCheck.current = true;
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setDriverTokenValidity({
          valid: false,
          message: "Token validation failed."
        });
        setStep(5);
      }
    };
    validateToken();
  }, [token, checkDriverTokenMutation, step, files, expiresIn]);

  // this would handle the expiration of the token, less load on our server, instead of using setInterval, or check after any action
  useEffect(() => {
    const now = Date.now();
    const timeUntilExpiration = new Date(expiresIn).getTime() - now;
    if (timeUntilExpiration > 0) {
      const timeout = setTimeout(() => {
        setStep(5);
      }, timeUntilExpiration);
      return () => clearTimeout(timeout);
    }
  }, [expiresIn]);

  const closingModal = commentInput => {
    if (commentInput === "limitExceeded") {
      pushNotification("Please upload a file under 10 MB", {
        color: "secondary",
        fixed: true
      });
      setStep(1);
      return;
    } else if (commentInput === "closeTab") {
      window.close();
      window.history.back();
      return;
    }

    TrackEvent("Upload document flow completed", {
      orderId: driverTokenValidity?.data?.order?._id,
      documentType: TBD_FILE_TYPE,
      documentName: files[0].name,
      clickSource: "Button",
      uploadByDriver: true,
      comment: commentInput
    });
  };

  const STEPS = [FirstStep, SecondStep, ThirdStep, ThankYouPage];
  const DefaultComponent = () => (
    <div>
      <h2>
        {urlParams.size === 0
          ? "The URL is not correct, please check it and try again"
          : "This session has expired"}
      </h2>
    </div>
  );

  const Component =
    step === null ? Loader : step > 4 ? DefaultComponent : STEPS[step - 1];

  return (
    <DocPreviewProvider
      token={driverTokenValidity?.data?.token}
      selectedCompany={driverTokenValidity?.data?.partner?.partnerId}
    >
      <>
        {Component !== DefaultComponent && <NavBar />}
        <Component
          files={files}
          setFiles={setFiles}
          setStep={setStep}
          closingModal={closingModal}
          accept={accept}
          driverTokenValidity={driverTokenValidity}
        />
      </>
    </DocPreviewProvider>
  );
}
